











import Vue from 'vue';

import { AzRedirectURI, AzTenant, logoutWithAzure } from '@/azureAd';

export default Vue.extend({
  name: 'azure-logout',

  data() {
    return {
      logoutUrl: `https://login.microsoftonline.com/${AzTenant}/oauth2/v2.0/logout?post_logout_redirect_uri=${AzRedirectURI}`,
    };
  },
  created() {
    logoutWithAzure();
  },
});
